<template >
    <div>
        <div class="order">
            <div class="printable">
                <div style="padding: 0 5px; width: 33.33%">
                    <b>WZ</b> Wydanie zewnętrzne
                    <table>
                        <tr>
                            <td>Index:</td>
                            <td>{{getWarehouseDetails.index}}</td>
                        </tr>
                        <tr>
                            <td>Miejsce i data wystawienia WZ:</td>
                            <td>{{getWarehouseDetails.wzDate}}</td>
                        </tr>
                        <tr>
                            <td>Data i czas sprzedaży:</td>
                            <td>{{getWarehouseDetails.wzDate}}</td>
                        </tr>
                    </table>
                    <table style="margin-top: 46px">
                        <tr>
                            <td>Pojazd:</td>
                            <td>{{getWarehouseDetails.carId ? getWarehouseDetails.carId.model +" " + getWarehouseDetails.carId.plate : getWarehouseDetails.carPlate}}</td>
                        </tr>
                        <tr>
                            <td>Kierowca:</td>
                            <td>{{getWarehouseDetails.carDriver}}</td>
                        </tr>
                    </table>
                </div>
                <div style="padding: 0 5px; width: 33.33%">
                    <b>Sprzedawca</b>
                    <table>
                        <tr>
                            <td style="height: 60px">Nazwa:</td>
                            <td>{{ getWarehouseDetails.company }}</td>
                        </tr>
                        <tr>
                            <td style="height: 60px">Adres:</td>
                            <td>{{getWarehouseDetails.companyAddress}}</td>
                        </tr>
                        <tr>
                            <td>Nr tel.:</td>
                            <td>{{getWarehouseDetails.companyPhoneNumber}}</td>
                        </tr>
                        <tr>
                            <td>E-mail:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>NIP:</td>
                            <td>{{getWarehouseDetails.companyNIP}}</td>
                        </tr>
                    </table>
                </div>
                <div style="padding: 0 5px; width: 33.33%">
                    <b>Nabywca</b>
                    <table>
                        <tr>
                            <td>Nazwa:</td>
                            <td>{{(getWarehouseDetails.constructionId && getWarehouseDetails.constructionId.investor) ? getWarehouseDetails.constructionId.investor.name : ''}}</td>
                        </tr>
                        <tr>
                            <td style="height: 60px">Adres:</td>
                            <td>{{correctAddress(getWarehouseDetails.constructionId)}}</td>
                        </tr>
                        <tr>
                            <td>Nr tel.:</td>
                            <td>{{(getWarehouseDetails.constructionId && getWarehouseDetails.constructionId.investor) ? getWarehouseDetails.constructionId.investor.phoneNumber : ""}}</td>
                        </tr>
                        <tr>
                            <td>E-mail:</td>
                            <td>{{(getWarehouseDetails.constructionId && getWarehouseDetails.constructionId.investor) ? getWarehouseDetails.constructionId.investor.email : ""}}</td>
                        </tr>
                        <tr>
                            <td>NIP:</td>
                            <td>{{(getWarehouseDetails.constructionId && getWarehouseDetails.constructionId.investor) ? getWarehouseDetails.constructionId.investor.NIP : ""}}</td>
                        </tr>
                        <tr>
                            <td>Budowa:</td>
                            <td>{{getWarehouseDetails.constructionId ? getWarehouseDetails.constructionId.name : ""}}</td>
                        </tr>
                    </table>
                </div>
                <div class="second-row">
                    <table>
                        <tr>
                            <th>Towar</th>
                            <th>jm.</th>
                            <th>Tara</th>
                            <th>Netto</th>
                            <th>Brutto</th>
                        </tr>
                        <tr>
                            <td>{{getWarehouseDetails.materialType ? getWarehouseDetails.materialType.name : ''}}</td>
                            <td>{{getWarehouseDetails.materialType ? getWarehouseDetails.materialType.unit : ''}}</td>
                            <td>{{Number(getWarehouseDetails.tareWeight).toFixed(2)}}</td>
                            <td>{{Number(Number(getWarehouseDetails.grossWeight) - Number(getWarehouseDetails.tareWeight)).toFixed(2)}}</td>
                            <td>{{Number(getWarehouseDetails.grossWeight).toFixed(2) }}</td>
                        </tr>
                    </table>
                    <span style="font-size:11px">{{getWarehouseDetails.description}}</span>
                </div>
                <div class="third-row">
                    <table>
                        <tr>
                            <th>Wydał</th>
                            <th>Pobrał</th>
                            <th>Odebrał</th>
                        </tr>
                        <tr style="height: 32px">
                            <td>{{getWarehouseDetails.createdBy.name + " " + getWarehouseDetails.createdBy.lastname}}</td>
                            <td>{{getWarehouseDetails.carDriver}}</td>
                            <td></td>
                        </tr>
                    </table>
                    <span class="accept">
                        Oświadczam, że samochód został załadowany ilościowo zgodnie z moją dyspozycją. Akceptuję ilość towaru załadowanego na samochód i biorę na siebie
                        wszelką odpowiedzialność za wynikłe z powyższego zdarzenia.
                    </span>

                    <div style="margin-left:600px; font-size:12px">
                        <br />....................................
                        <br />podpis pobierającego
                    </div>
                </div>
            </div>
        </div>
        <v-btn dark class="buttons--add no-print order__btn" fab small @click="print()">
            <v-icon size="18" dark>mdi-printer</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["getWarehouseDetails"]),
    },
    methods: {
        print() {
            window.print();
        },
        correctAddress(constructionId) {
            let address = "";
            if (constructionId && constructionId.investor) {
                address = `${constructionId.investor.street} ${
                    constructionId.investor.houseNumber
                } ${
                    constructionId.investor.apartmentNumber
                        ? "/" + constructionId.investor.apartmentNumber
                        : ""
                } ${constructionId.investor.postCode} ${
                    constructionId.investor.city
                } `;
            }
            return address;
        },
    },
};
</script>

<style scoped>
.printable {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
table {
    margin-top: 4px;
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
}
td {
    font-weight: 500;
    border: 1px solid black;
    padding: 8px;
    font-size: 11px;
    border-collapse: collapse;
    word-break: normal;
}
.order {
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.accept {
    font-size: 11px;
    margin-top: 15px;
    display: block;
    width: 99.88%;
}
.signature {
    position: absolute;
    right: 40px;
    font-size: 11px;
    bottom: -50px;
}
.second-row {
    margin-top: 40px;
    padding: 0 5px;
    width: 99.88%;
}
.third-row {
    margin-top: 25px;
    padding: 0 5px;
    position: relative;
    width: 99.88%;
}
@media print {
    .order {
        height: 100%;
        padding: 0px;
        box-shadow: none;
        top: 0;
        font-size: 11px;
        width: 100%;
        position: absolute;
        transform: translate(-50%, 0%);
    }
    td {
        font-size: 10px;
        padding: 4px 5px;
    }
    .accept {
        font-size: 10px;
        margin-top: 10px;
    }
    .second-row {
        margin-top: 10px;
        padding: 0;
    }
    .third-row {
        margin-top: 15px;
        padding: 0;
        position: relative;
    }
    .signature {
        position: absolute;
        right: 40px;
        padding: 0;
        font-size: 11px;
        bottom: -50px;
    }
    .no-print {
        display: none !important;
    }
}
</style>